import { v4 as uuidv4 } from 'uuid';

const getUserId = () => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
        userId = uuidv4();
        localStorage.setItem('userId', userId);
    }
    return userId;
};

const trackEvent = async (eventName, params) => {
    const userId = getUserId();

    fetch('/api/track', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventName, params, userId }),
    }).catch((error) => {
        console.error('Error tracking event:', error);
    });
};

export default trackEvent;
