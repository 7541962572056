// Interests.js
import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import {CenteredContainer} from "../styled/HomeStyles";

const InterestsSectionComponent = () => {
    const [interests, setInterests] = useState([]);

    useEffect(() => {
        fetch('/Information/interests.json')
            .then(response => response.json())
            .then(data => setInterests(data));
    }, []);

    return (
        <div>
            <CenteredContainer>
                <h1>My Interests</h1>
            </CenteredContainer>
            {interests.length > 0 && <Carousel items={interests} visibleCount={3} />}
        </div>
    );
};

export default InterestsSectionComponent;
