// Carousel.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import InterestCard from "./InterestCard";

const CarouselWrapper = styled.div`
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
`;

const CarouselContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
    margin: 0 auto;
`;

const Button = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    @media (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
`;

const LeftButton = styled(Button)`
    left: -60px;

    @media (max-width: 768px) {
        left: -30px;
    }
`;

const RightButton = styled(Button)`
    right: -60px;

    @media (max-width: 768px) {
        right: -30px;
    }
`;

const CardContainer = styled.div`
    overflow: hidden;
    width: 100%;
`;

const CardsWrapper = styled.div`
    display: flex;
    transition: transform 0.5s ease-in-out;
    transform: ${(props) => `translateX(-${props.index * props.cardWidth}px)`};
    width: ${(props) => `${(props.items.length * props.cardWidth)}px`};
`;

const Carousel = ({ items }) => {
    const [index, setIndex] = useState(0);
    const [visibleCount, setVisibleCount] = useState(3);
    const [cardWidth, setCardWidth] = useState(400); // Set a default fixed card width

    useEffect(() => {
        const updateVisibleCount = () => {
            const containerWidth = window.innerWidth * 0.8; // Assuming padding of 20px on each side
            const newVisibleCount = Math.floor(containerWidth / cardWidth);
            setVisibleCount(newVisibleCount < 1 ? 1 : newVisibleCount);
        };

        const updateCardWidth = () => {
            if (window.innerWidth <= 768) {
                setCardWidth(290); // Smaller width for mobile
            } else {
                setCardWidth(400); // Default width for larger screens
            }
        };

        window.addEventListener('resize', updateVisibleCount);
        window.addEventListener('resize', updateCardWidth);
        updateVisibleCount();
        updateCardWidth();

        return () => {
            window.removeEventListener('resize', updateVisibleCount);
            window.removeEventListener('resize', updateCardWidth);
        };
    }, [cardWidth]);

    const handlePrev = () => {
        setIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    return (
        <CarouselWrapper>
            <CarouselContainer>
                <LeftButton onClick={handlePrev}>
                    <ArrowBackIos />
                </LeftButton>
                <CardContainer>
                    <CardsWrapper index={index} visibleCount={visibleCount} items={items} cardWidth={cardWidth}>
                        {items.map((item, i) => (
                            <div
                                key={i}
                                style={{
                                    flex: `0 0 ${cardWidth}px`,
                                    boxSizing: 'border-box',
                                    padding: '0 10px',
                                    maxWidth: `${cardWidth}px`, // Ensure the card does not exceed the fixed width
                                }}
                            >
                                <InterestCard interest={item} />
                            </div>
                        ))}
                    </CardsWrapper>
                </CardContainer>
                <RightButton onClick={handleNext}>
                    <ArrowForwardIos />
                </RightButton>
            </CarouselContainer>
        </CarouselWrapper>
    );
};

export default Carousel;
