import HeaderSection from "../components/Solutions/HeaderSection";
import ServicesComponent from "../components/Solutions/Services";
import ContactForm from "../components/Solutions/ContactForm";
import AboutSectionComponent from "../components/Solutions/AboutSection";
import {Box, Container, Paper} from "@mui/material";
import styled from "styled-components";
import {useRef} from "react";

const ContactSection = styled(Box)`
  padding: 50px 20px;
  margin: 20px 0;
`;

// const ContactForm = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   max-width: 600px;
//   margin: 0 auto;
// `;

const SolutionsPage = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add form submission logic here
    };
    const contactRef = useRef(null);

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <HeaderSection scrollToContact={scrollToContact}/>
            <AboutSectionComponent/>
            <ServicesComponent/>
            <div ref={contactRef}>
                <ContactForm/>
            </div>
        </>
    );
};

export default SolutionsPage;
