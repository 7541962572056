// InterestCard.js
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Gallery from './Gallery';

const CardContainer = styled.div`
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0 30px 0;
    text-align: center;
    cursor: pointer;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
        &::after {
            content: 'Click to learn more';
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
        }
    }
`;

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
`;

const Title = styled.h5`
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
`;

const InterestCard = ({ interest }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/interest/${interest.id}`);
    };

    return (
        <CardContainer onClick={handleClick}>
            <GalleryContainer>
                <Gallery images={interest.images}/>
            </GalleryContainer>
            <Title>{interest.title}</Title>
        </CardContainer>
    );
};

export default InterestCard;
