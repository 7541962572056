import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
    margin-top: 20px;
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyPolicyContainer>
            <Title>Privacy Policy</Title>
            <p>
                This Privacy Policy describes how your personal information is collected, used, and shared when you visit or interact with our website.
            </p>
            <SectionTitle>Google Analytics Advertising Features</SectionTitle>
            <p>
                We have implemented the following Google Analytics Advertising Features:
                <ul>
                    <li>Google Analytics Demographics and Interest Reporting</li>
                </ul>
                We, along with third-party vendors such as Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as Google advertising cookies) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.
            </p>
            <SectionTitle>Opt-Out</SectionTitle>
            <p>
                Users can opt-out of the Google Analytics Advertising Features we use through:
                <ul>
                    <li><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">Google Ads Settings</a></li>
                    <li><a href="https://www.google.com/settings/ads/plugin" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a></li>
                    <li>Other available methods (e.g., the NAI's consumer opt-out)</li>
                </ul>
            </p>
            <SectionTitle>User-ID</SectionTitle>
            <p>
                We use a unique identifier for each user to provide a more accurate user count and to understand your interactions with our website across different devices and sessions. This user-ID is not personally identifiable information.
            </p>
            <SectionTitle>European Union User-Consent Policy</SectionTitle>
            <p>
                If you are located in the European Union, you have certain rights under the General Data Protection Regulation (GDPR). These include the right to access, correct, update, or request deletion of your personal information. If you wish to exercise these rights, please contact us using the information below.
            </p>
            <SectionTitle>Interest-Based Advertising</SectionTitle>
            <p>
                We use Google Analytics to collect data about your interests and demographics. This data is used to provide you with targeted advertisements based on your interests and browsing behavior. We do not use Google Analytics to collect sensitive information about you.
            </p>
            <SectionTitle>Data Retention</SectionTitle>
            <p>
                We retain your personal information for as long as necessary to provide you with our services and for other essential purposes such as complying with legal obligations, resolving disputes, and enforcing our policies.
            </p>
            <SectionTitle>Changes</SectionTitle>
            <p>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
            </p>
            <SectionTitle>Contact Us</SectionTitle>
            <p>
                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at rsweeney@smu.edu.
            </p>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
