// styled/SkillsStyles.js
import styled from 'styled-components';

export const SkillsSection = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  color: #242424;
`;

export const SkillList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const SkillItem = styled.li`
  margin: 10px 20px 10px 0px;
  font-size: 16px;
  font-weight: bold;


  a {
      color: #4ABDAC;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
          color: #028090;
      }
  }
`;

export const IntroText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const DetailButton = styled.button`
  background-color: #4ABDAC;
  color: #fff;
  border: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #028090;
  }
`;
