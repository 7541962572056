import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
`;

const SectionTitle = styled.h2`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 2em;
`;

const ProjectsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
`;

const ProjectCard = styled.div`
    padding: 20px;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;


    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
`;

const ProjectImage = styled.img`
    max-width: 100%;
    height: 350px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const ProjectTitle = styled.h3`
    margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const ProjectReason = styled.p`
    font-style: italic;
    margin-bottom: 10px;
`;

const ProjectLink = styled(Link)`
    display: inline-block;
    padding: 10px 20px;
    background: #4ABDAC;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;

    &:hover {
        background-color: #21554d;
    }
`;

const LanguagesContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

const LanguageLogo = styled.img`
    width: 30px;
    height: 30px;
    margin: 0 5px;
`;

const SearchBar = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const FilterContainer = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const FilterButton = styled.button`
    padding: 10px 20px;
    margin: 5px;
    background: #4ABDAC;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #21554d;
    }

    &.active {
        background-color: #21554d;
    }
`;

const ProjectsPage = () => {
    const [content, setContent] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        fetch('Information/projects.json')
            .then(response => response.json())
            .then(data => setContent(data))
            .catch(error => console.error('Error fetching content:', error));
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleFilterClick = (language) => {
        setFilter(filter === language ? null : language);
    };

    const filterProjects = (projects) => {
        return projects.filter(project => {
            const matchesSearchTerm = project.title.toLowerCase().includes(searchTerm) || project.description.toLowerCase().includes(searchTerm) || project.projectReason.toLowerCase().includes(searchTerm) || project.languages.some(language => language.toLowerCase().includes(searchTerm));
            const matchesFilter = filter ? project.languages.includes(filter) : true;
            return matchesSearchTerm && matchesFilter;
        });
    };

    if (!content) {
        return <div>Loading...</div>;
    }

    const allLanguages = [...new Set(content.projects.flatMap(project => project.languages))];

    return (
        <PageContainer>
            <SectionTitle>My Projects</SectionTitle>
            <SearchBar type="text" placeholder="Search projects..." value={searchTerm} onChange={handleSearchChange} />
            <FilterContainer>
                {allLanguages.map((language, index) => (
                    <FilterButton key={index} onClick={() => handleFilterClick(language)} className={filter === language ? 'active' : ''}>
                        {language}
                    </FilterButton>
                ))}
            </FilterContainer>
            <ProjectsContainer>
                {filterProjects(content.projects).map((project, index) => (
                    <ProjectCard key={index}>
                        <ProjectImage src={project.image} alt={project.title} />
                        <ProjectTitle>{project.title}</ProjectTitle>
                        <ProjectDescription>{project.description}</ProjectDescription>
                        <ProjectReason>{project.projectReason}</ProjectReason>
                        <ProjectLink to={project.customPage ? project.link : `/projects/${project.projectID}`}>
                            Learn More
                        </ProjectLink>
                        <LanguagesContainer>
                            {project.languages.map((language, i) => (
                                <LanguageLogo key={i} src={`/assets/logos/${language.toLowerCase()}.svg`} alt={language} />
                            ))}
                        </LanguagesContainer>
                    </ProjectCard>
                ))}
                <ProjectCard>
                    <ProjectTitle>More projects coming soon...</ProjectTitle>
                    <ProjectDescription>Stay tuned for more exciting projects!</ProjectDescription>
                </ProjectCard>
            </ProjectsContainer>
        </PageContainer>
    );
};

export default ProjectsPage;
