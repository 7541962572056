import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';

const PageContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    text-align: center;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
    margin-top: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
`;

const PRList = styled.ul`
    list-style: none;
    padding: 0;
`;

const PRItem = styled.li`
    margin-bottom: 10px;
`;

const CountdownContainer = styled.div`
  font-size: 48px;
  font-family: 'Digital-7', sans-serif;
  color: #ff4500;
  margin-bottom: 20px;
`;

const RunningInterestPage = () => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        fetch('/Information/runningContent.json')
            .then(response => response.json())
            .then(data => setContent(data))
            .catch(error => console.error('Error fetching content:', error));
    }, []);

    if (!content) {
        return <div>Loading...</div>;
    }

    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <CountdownContainer>
                {days}d {hours}h {minutes}m {seconds}s
            </CountdownContainer>
        );
    };

    return (
        <PageContainer>
            <Title>My Running Journey</Title>

            <Image src={content.image} alt="Running" />

            <SectionTitle>Countdown to Berlin Marathon</SectionTitle>
            <Countdown date={new Date(content.berlinMarathonDate)} renderer={renderer} />

            <SectionTitle>About My Journey</SectionTitle>
            <p>{content.journey}</p>

            <SectionTitle>Personal Records (PRs)</SectionTitle>
            <PRList>
                <PRItem>800m: {content.prs['800m']}</PRItem>
                <PRItem>1600m: {content.prs['1600m']}</PRItem>
                <PRItem>3200m: {content.prs['3200m']}</PRItem>
                <PRItem>5k: {content.prs['5k']}</PRItem>
                <PRItem>Half Marathon: {content.prs['halfMarathon']}</PRItem>
                <PRItem>Marathon: {content.prs['marathon']}</PRItem>
            </PRList>
        </PageContainer>
    );
};

export default RunningInterestPage;
