// Organizations.js
import React from 'react';
import {
    LayoutColorful2,
    OrganizationsSection,
    TextH2,
    TextField2,
    TextH5Color, CenteredContainer
} from '../styled/HomeStyles';

const OrganizationsSectionComponent = () => (
    <LayoutColorful2>
        <CenteredContainer>
            <OrganizationsSection>
                <TextH2>Organizations</TextH2>
                <TextField2>
                    <TextH5Color as="a" href="https://deltasig.org" target="_blank" rel="noreferrer">Delta Sigma Phi - Lambda Chapter</TextH5Color>
                    <TextH5Color as="a" href="https://phichitheta.org/" target="_blank" rel="noreferrer">Phi Chi Theta - Alpha Gamma Chapter</TextH5Color>
                    <TextH5Color as="a" href="https://thetatau.org/" target="_blank" rel="noreferrer">Theta Tau - Tau Beta Chapter</TextH5Color>
                    <TextH5Color as="a" href="https://www.smucatholic.org/" target="_blank" rel="noreferrer">Catholic Campus Ministry at SMU</TextH5Color>
                    <TextH5Color as="a" href="https://www.scouting.org/" target="_blank" rel="noreferrer">Boy Scouts of America</TextH5Color>
                </TextField2>
            </OrganizationsSection>
        </CenteredContainer>
    </LayoutColorful2>
);

export default OrganizationsSectionComponent;
