// InterestDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Gallery from '../components/Gallery';

const DetailContainer = styled.div`
    padding: 20px;
    margin: auto;
    max-width: 80%;
`;

const Title = styled.h2`
    font-size: 24px;
    font-weight: bold;
`;

const Description = styled.p`
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
`;

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 600px;
`;


const DefaultInterestPage = () => {
    const { id } = useParams();
    const [interest, setInterest] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch('/Information/interests.json')
            .then(response => response.json())
            .then(data => {
                const foundInterest = data.find(item => item.id === id);
                setInterest(foundInterest);
                if (foundInterest) {
                    const loadedImages = foundInterest.images
                    setImages(loadedImages);
                }
            });
    }, [id]);

    if (!interest) {
        return <p>Loading...</p>;
    }

    return (
        <DetailContainer>
            <Title>{interest.title}</Title>
            <Description>{interest.description}</Description>
            <GalleryContainer>
                <Gallery images={images} />
            </GalleryContainer>
        </DetailContainer>
    );
};

export default DefaultInterestPage;
