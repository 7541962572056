// Home.js
import React from 'react';
import IntroductionSection from '../components/Home/Introduction';
import AboutSection from '../components/Home/About';
import SkillsSectionComponent from '../components/Home/Skills';
import OrganizationsSectionComponent from '../components/Home/Organizations';
import InterestsSectionComponent from '../components/Home/Interests';
import AwardsSectionComponent from '../components/Home/Awards';
import { MediaQueries} from '../components/styled/HomeStyles';

const Home = () => (
    <MediaQueries>
        <IntroductionSection />
        <AboutSection />
        <SkillsSectionComponent />
        <OrganizationsSectionComponent />
        <InterestsSectionComponent />
        <AwardsSectionComponent />
    </MediaQueries>
);

export default Home;
