import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaGithub } from 'react-icons/fa';

const PageContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    display: flex;
    flex-wrap: wrap;
`;

const LeftContainer = styled.div`
    flex: 1 1 60%;
    padding: 20px;
`;

const RightContainer = styled.div`
    flex: 1 1 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h1`
    text-align: left;
    margin-bottom: 20px;
`;

const ProjectImage = styled.img`
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Description = styled.div`
  margin-bottom: 20px;
`;

const Authors = styled.div`
  margin-bottom: 20px;
`;

const LanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const LanguageLogo = styled.img`
  width: 30px;
  height: 30px;
`;

const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${props => (props.disabled ? '#ddd' : '#007bff')};
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${props => (props.disabled ? '#ddd' : '#0056b3')};
  }
`;

const GitHubIcon = styled(FaGithub)`
  margin-right: 10px;
`;

const DefaultProjectDetailPage = () => {
    const { projectID } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        fetch('/Information/projects.json')
            .then(response => response.json())
            .then(data => {
                const projectData = data.projects.find(p => p.projectID === projectID);
                setProject(projectData);
            })
            .catch(error => console.error('Error fetching project data:', error));
    }, [projectID]);

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <PageContainer>
            <LeftContainer>
                <Title>{project.title}</Title>
                <Description dangerouslySetInnerHTML={{ __html: project.detailedDescription }} />
                {project.authors && (
                    <Authors>
                        <strong>Authors:</strong> {project.authors.join(', ')}
                    </Authors>
                )}
                <LanguagesContainer>
                    {project.languages.map((language, i) => (
                        <LanguageLogo key={i} src={`/assets/logos/${language.toLowerCase()}.svg`} alt={language} />
                    ))}
                </LanguagesContainer>
                <ExternalLink
                    href={project.repository || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!project.repository}
                >
                    <GitHubIcon />
                    {project.repository ? 'Link to repo' : 'Project source code not available'}
                </ExternalLink>
            </LeftContainer>
            <RightContainer>
                <ProjectImage src={project.image} alt={project.title} />
            </RightContainer>
        </PageContainer>
    );
};

export default DefaultProjectDetailPage;
