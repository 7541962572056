import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import styled from "styled-components";

const ContactSection = styled(Box)`
    padding: 50px 20px;
    margin: auto;
`;

const ContactForm = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width:100%;
`;

const ContactFormComponent = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await fetch('/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message }),
        });

        if (response.ok) {
            // Handle successful submission (e.g., clear the form, show a message)
            setName('');
            setEmail('');
            setMessage('');
            alert('Message sent successfully!');
        } else {
            // Handle error
            alert('Failed to send message.');
        }
    };

    return (
        <Container>
            <ContactSection>
                <Typography variant="h4" gutterBottom>
                    Contact Me
                </Typography>
                <ContactForm component="form" onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        margin="normal"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        margin="normal"
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Message"
                        margin="normal"
                        multiline
                        rows={4}
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button variant="contained" style={{background: "#4ABDAC"}} type="submit">
                        Submit
                    </Button>
                </ContactForm>
            </ContactSection>
        </Container>
    );
};

export default ContactFormComponent;
