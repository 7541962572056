// Awards.js
import React from 'react';
import {
    LayoutColorful2,
    AwardsSection,
    TextH2,
    TextField2,
    TextH5Color, CenteredContainer
} from '../styled/HomeStyles';

const AwardsSectionComponent = () => (
    <LayoutColorful2>
        <CenteredContainer>
            <AwardsSection>
                <TextH2>Awards</TextH2>
                <TextField2>
                    <TextH5Color>Eagle Scout</TextH5Color>
                    <TextH5Color>SMU Distinguished Scholar</TextH5Color>
                    <TextH5Color>SMU Discovery Scholar</TextH5Color>
                    <TextH5Color>SMU Hilltop Scholar Honors Student</TextH5Color>
                </TextField2>
            </AwardsSection>
        </CenteredContainer>
    </LayoutColorful2>
);

export default AwardsSectionComponent;
