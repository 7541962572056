import React from "react";
import "./footer.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub} from "@fortawesome/free-brands-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    padding: 20px;
    text-align: center;
`;

const FooterLink = styled(Link)`
    color: #4ABDAC;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const FooterLinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-left: 20vw;
    height: 100%;
`

const FooterLinkItems = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    color: #fff;
    width: 80%;
    margin-left: 20%;
`

function Footer() {
    return (
            <FooterLinkItems>
                <a className = "github" href={"https://github.com/RyanWSweeney"} target = "_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} />
                </a>
                <a className="linked-in" href={"https://www.linkedin.com/in/ryanwsweeney/"} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <FooterContainer style={{margin: '0 0 0 40%'}}>
                    <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
                </FooterContainer>
            </FooterLinkItems>
    );
}

export default Footer;