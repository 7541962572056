import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import styled from "styled-components";
import CodeIcon from '@mui/icons-material/Code';
import BuildIcon from '@mui/icons-material/Build';
import MemoryIcon from '@mui/icons-material/Memory';
import InsightsIcon from '@mui/icons-material/Insights';
import DataUsageIcon from '@mui/icons-material/DataUsage';

const ServicesSection = styled(Box)`
    padding: 50px 20px;
    background-color: #f9f9f9;
`;

const ServiceCard = styled(Paper)`
    padding: 20px;
    text-align: center;
    transition: .5s;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
        transition: .5s;
    }
`;

const services = [
    {
        "title": "Automation Consulting",
        "description": "Streamline your workflows with advanced automation solutions tailored to your business needs.",
        "icon": <BuildIcon style={{ fontSize: 60 }} />
    },
    {
        "title": "Software Development",
        "description": "Custom software solutions to enhance your business processes and increase efficiency.",
        "icon": <CodeIcon style={{ fontSize: 60 }} />
    },
    {
        "title": "Hardware Integration",
        "description": "Seamless integration of hardware and software to create comprehensive automation systems.",
        "icon": <MemoryIcon style={{ fontSize: 60 }} />
    },
    {
        "title": "AI & Machine Learning",
        "description": "Implement intelligent systems to improve decision-making and automate complex processes.",
        "icon": <InsightsIcon style={{ fontSize: 60 }} />
    },
    {
        "title": "Data Analytics",
        "description": "Unlock insights from your data to drive strategic business decisions.",
        "icon": <DataUsageIcon style={{ fontSize: 60 }} />
    }
];

const ServicesComponent = () => {
    return (
        <ServicesSection>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Services
                </Typography>
                <Grid container spacing={4}>
                    {services.map((service, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <ServiceCard elevation={3}>
                                {service.icon}
                                <Typography variant="h6">{service.title}</Typography>
                                <Typography variant="body2">
                                    {service.description}
                                </Typography>
                            </ServiceCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ServicesSection>
    );
};

export default ServicesComponent;
