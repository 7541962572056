// Gallery.js
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const Image = styled(motion.img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
`;

const variants = {
    enter: (direction) => ({
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
        zIndex: 0,
    }),
    center: {
        x: 0,
        opacity: 1,
        zIndex: 1,
    },
    exit: (direction) => ({
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
        zIndex: 0,
    }),
};

const Gallery = ({ images }) => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            paginate(1);
        }, 10000); // Change interval duration here for overall speed
        return () => clearInterval(interval);
    }, [page]);

    const imageIndex = (page + images.length) % images.length;

    return (
        <>
            <AnimatePresence initial={false} custom={direction}>
                <Image
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: 'spring', stiffness: 300, damping: 30, duration: 0.8 },
                    }}
                />
            </AnimatePresence>
        </>
    );
};

export default Gallery;
