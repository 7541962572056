// InterestDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Gallery from './Gallery';
import loadImages from '../utils/loadImages';

const DetailContainer = styled.div`
    padding: 20px;
`;

const Title = styled.h2`
    font-size: 24px;
    font-weight: bold;
`;

const Description = styled.p`
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
`;

const InterestDetail = () => {
    const { id } = useParams();
    const [interest, setInterest] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch('/Information/interests.json')
            .then(response => response.json())
            .then(data => {
                const foundInterest = data.find(item => item.id === id);
                setInterest(foundInterest);
                if (foundInterest) {
                    const loadedImages = loadImages(foundInterest.imageFolder, id);
                    setImages(loadedImages);
                }
            });
    }, [id]);

    if (!interest) {
        return <p>Loading...</p>;
    }

    return (
        <DetailContainer>
            <Title>{interest.title}</Title>
            <Description>{interest.description}</Description>
            <Gallery images={images} />
        </DetailContainer>
    );
};

export default InterestDetail;
