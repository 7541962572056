// Skills.js
import React, { useEffect, useState } from 'react';
import { SkillsSection, SkillList, SkillItem, IntroText, DetailButton } from '../styled/SkillsStyles';
import { useNavigate } from 'react-router-dom';
import {CenteredContainer} from "../styled/HomeStyles"; // Ensure react-router-dom is installed
const SkillsSectionComponent = () => {
    const [skills, setSkills] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/Information/skills.json')
            .then(response => response.json())
            .then(data => setSkills(data));
    }, []);

    const navigateToDetails = () => {
        navigate('/projects');
    };

    return (
        <SkillsSection>
            <CenteredContainer>
                <h2>Programming Skills</h2>
                <SkillList>
                    {skills.map(skill => (
                        <SkillItem key={skill.name}>
                            <a href={skill.link} target="_blank" rel="noopener noreferrer">{skill.name}</a>
                        </SkillItem>
                    ))}
                </SkillList>
                <DetailButton onClick={navigateToDetails}>Read About My Programming Journey</DetailButton>
            </CenteredContainer>
        </SkillsSection>
    );
};

export default SkillsSectionComponent;
