// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
    ReactGA.initialize('G-LQSR9781MW'); // Replace with your GA4 measurement ID
};

export const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};
