import React, {useEffect, useState} from 'react';
import {
    LayoutColorful,
    TextField2,
    TextH2Light,
    TextH4Light,
    Button2,
    CenteredContainer
} from '../styled/HomeStyles';
import trackEvent from '../../utils/tracking'; // Import the tracking function

const AboutSection = () => {
    const handleLinkedInClick = () => {
        trackEvent('button_click', { button: 'LinkedIn' });
    };

    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('/Information/about.json')
            .then(response => response.json())
            .then(data => setContent(data.about))
            .catch(error => console.error('Error fetching content:', error));
    }, []);

    return (
        <LayoutColorful>
            <CenteredContainer>
                <TextField2>
                    <TextH2Light>About Me</TextH2Light>
                    <TextH4Light>{content}</TextH4Light>
                    <Button2
                        href="https://www.linkedin.com/in/ryanwsweeney/"
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleLinkedInClick} // Add onClick handler
                    >
                        LinkedIn
                    </Button2>
                </TextField2>
            </CenteredContainer>
        </LayoutColorful>
    );
};

export default AboutSection;
