import React from 'react';
import { Container, Typography, Button, Box, TextField } from '@mui/material';
import styled from 'styled-components';

const Header = styled(Box)`
    background: linear-gradient(45deg, #586eaa 30%, #18714a 90%);
    padding: 100px 20px;
    color: white;
    text-align: center;
`;

const HeaderSection = ({ scrollToContact }) => {
    return (
        <Header>
            <Container>
                <Typography variant="h2" gutterBottom>
                    Cutting-Edge Software Solutions
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Expert in Automation Consulting and Workflow Optimization
                </Typography>
                <Button variant="contained" style={{background: "#4ABDAC"}} onClick={scrollToContact}>
                    Get in Touch
                </Button>
            </Container>
        </Header>
    );
};

export default HeaderSection;
